import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import { Link } from 'gatsby';
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import heroImg from '../../images/posts/007/highlight-text.svg';
import HighlightText from '../../components/PostExtras/HighlightText/highlight-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <HeroPost gradientFrom="#1A112A" gradientTo="#000000" mdxType="HeroPost">
  <img src={heroImg} alt="Highlight text in JavaScript" height="107" />
    </HeroPost>
    <h1>{`Highlight matching text in JavaScript`}</h1>
    <Date date="January 07, 2021" dateMachine="2021-01-07" mdxType="Date" />
    <p>{`In the previous post about `}<Link to="/posts/search-with-typo-tolerance" mdxType="Link">{`search with typo tolerance`}</Link>{`, I added a few interactive elements
to demonstrate the idea of how we can improve search functionality on the page by being more tolerant to typos.
You might be curious how I made highlighting of matching text within results. So here it is.`}</p>
    <p>{`It's not super complicated but I'll give you a very nice hint you might not know :) `}<strong parentName="p">{`Here is the demo`}</strong>{`. Use Search input to search
trough given text:`}</p>
    <HighlightText text="Alongside HTML and CSS, JavaScript is one of the core technologies
        of the World Wide Web. JavaScript enables interactive web pages and
        is an essential part of web applications. The vast majority of
        websites use it for client-side page behavior, and all major web
        browsers have a dedicated JavaScript engine to execute it." mdxType="HighlightText" />
    <p>{`The trick is to replace all occurrences of searched text with the same text but wrapped with a `}<inlineCode parentName="p">{`<mark>`}</inlineCode>{` this time.
We will also add a `}<inlineCode parentName="p">{`highlight`}</inlineCode>{` CSS class to that `}<inlineCode parentName="p">{`<mark>`}</inlineCode>{` so we will be able to style it accordingly.
`}<strong parentName="p">{`You don't need any JS library for that.`}</strong>{` Here is the code that does the job:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{11},{showLines},{codepenUrl=https://codepen.io/tniezurawski/pen/wvzyVEE}",
        "{11},{showLines},{codepenUrl": "https://codepen.io/tniezurawski/pen/wvzyVEE}"
      }}>{`const $box = document.getElementById('box');
const $search = document.getElementById('search');

$search.addEventListener('input', (event) => {
  const searchText = event.target.value;
  const regex = new RegExp(searchText, 'gi');

  let text = $box.innerHTML;
  text = text.replace(/(<mark class="highlight">|<\\/mark>)/gim, '');

  const newText = text.replace(regex, '<mark class="highlight">$&</mark>');
  $box.innerHTML = newText;
});
`}</code></pre>
    <p>{`Let's assume the `}<inlineCode parentName="p">{`$box`}</inlineCode>{` is the element that contains text (it could be a whole page) and the `}<inlineCode parentName="p">{`$search`}</inlineCode>{` is the input.
In line `}<span className="line-number">{`8`}</span>{` we get the current HTML in the `}<inlineCode parentName="p">{`$box`}</inlineCode>{` and remove all current highlights
in the following line. We do that to clean-up after ourselves. We don't want to keep old searches (or partial searches) on the screen.
You can `}<a parentName="p" {...{
        "href": "https://codepen.io/tniezurawski/pen/wvzyVEE"
      }}>{`play with that on codepen`}</a>{` so you'll see the HTML structure and
CSS styles (where only the .highlight is important).`}</p>
    <p>{`The hint I've mentioned before you could potentially miss is `}<inlineCode parentName="p">{`$&`}</inlineCode>{` in the second argument of the `}<inlineCode parentName="p">{`replace`}</inlineCode>{` method. This is
`}<em parentName="p">{`a special replacement pattern`}</em>{` that tells the replacer method to `}<em parentName="p">{`insert the matched substring`}</em>{` there.`}</p>
    <p>{`Why we won't simply use something like this? So inserting the searched text?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{6}",
        "{6}": true
      }}>{`// ...
const searchText = event.target.value;
// ...
const newText = text.replace(
  regex,
  \`<mark class="highlight">\${searchText}</mark>\`
);
`}</code></pre>
    <p>{`By doing that `}<strong parentName="p">{`we will get into trouble with the case of the letters`}</strong>{`. Most search/find functionality is
case insensitive so we don't want to mess with that. Consider the example below, where I simply wrap the searched text
with a `}<inlineCode parentName="p">{`<mark>`}</inlineCode>{` with that text inside:`}</p>
    <HighlightText text="JAVASCRIPT ENABLES INTERACTIVE WEB PAGES AND IS AN ESSENTIAL PART OF WEB APPLICATIONS" typeExample="interactive" smartReplacement={false} mdxType="HighlightText" />
    <p>{`It's strange, isn't it? `}<strong parentName="p">{`Fortunately, we don't have to be super clever`}</strong>{` to keep the case of the matched text. We just need
to use `}<inlineCode parentName="p">{`$&`}</inlineCode>{` with the `}<inlineCode parentName="p">{`replace`}</inlineCode>{` method.`}</p>
    <h2>{`React implementation`}</h2>
    <p>{`React seems to be the most popular `}<del parentName="p">{`framework`}</del>{` library that people use these days. But no matter what front-end
framework you use, you'll probably pass `}<inlineCode parentName="p">{`text`}</inlineCode>{` as an argument to a component with search-and-highlight functionality.
It could be also a label of searchable items on a list.`}</p>
    <p>{`That simplifies things a bit because we don't have to get a raw text from DOM elements. And we don't have to
clean up after ourselves. We can focus on the wrapping part and leave the rendering to the rendering engine:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "{20-21},{showLines},{codesandboxUrl=https://codesandbox.io/s/zealous-blackburn-54310?file=/src/HighlightText.js}",
        "{20-21},{showLines},{codesandboxUrl": "https://codesandbox.io/s/zealous-blackburn-54310?file"
      }}>{`import React, { Component } from 'react';

export default class HighlightText extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: '' };
    this.search = this.search.bind(this);
  }

  search(event) {
    this.setState({ searchText: event.target.value });
  }

  _getText(text, searchText) {
    return searchText ? this._getTextWithHighlights(text, searchText) : text;
  }

  _getTextWithHighlights(text, searchText) {
    const regex = new RegExp(searchText, 'gi');
    const newText = text.replace(regex, \`<mark class="highlight">$&</mark>\`);
    return <span dangerouslySetInnerHTML={{ __html: newText }} />;
  }

  render() {
    const { cite, text } = this.props;
    const { searchText } = this.state;
    const textToShow = this._getText(text, searchText);

    return (
      <div className="container">
        <div className="search-container">
          <label htmlFor="search">Search within quoted text</label>
          <input
            id="search"
            placeholder="Type \`web\` for example"
            type="search"
            autoComplete="off"
            onChange={this.search}
            value={searchText}
          />
        </div>
        <blockquote cite={cite}>{textToShow}</blockquote>
      </div>
    );
  }
}
`}</code></pre>
    <p>{`The most important lines in this implementation are lines `}<span className="line-number">{`20`}</span>{` and `}<span className="line-number">{`21`}</span>{`.
The first one is the heart of highlighting implementation and the second makes sure to set `}<em parentName="p">{`dangerous HTML`}</em>{` content within an element.`}</p>
    <h3>{`What's so dangerous about the wrapped searched text?`}</h3>
    <p><strong parentName="p">{`Every framework has to sanitize raw HTML`}</strong>{` if you plan to display it on the screen. Here we are sure that the content is ok.
It's provided by the user but not displayed anywhere else than their computer so it's safe by definition.`}</p>
    <p>{`Search for "html safe `}<em parentName="p">{`+ framework name`}</em>{`" to find a way to force the rendering engine to display a wrapped element.`}</p>
    <p>{`Good luck!`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1347110666419765249" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Search with typo tolerance" prevLink="/posts/search-with-typo-tolerance" nextTitle="Throttle vs Debounce on real examples" nextLink="/posts/throttle-vs-debounce-on-real-examples" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      